/* eslint-disable no-undef */
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import compact from 'lodash/compact';
import React, { useRef, useMemo, useCallback, useEffect } from 'react';
import { useDDPCall, useDDPSubscription } from '@zedoc/ddp-connector';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ContentCopy } from 'styled-icons/material';
import {
  apiAdminOneVariable,
  apiAdminCreateVariable,
  apiAdminUpdateVariable,
} from '../../../common/api/admin';
import {
  ADMIN_UPDATE_VARIABLE,
  ADMIN_CREATE_VARIABLE,
} from '../../../common/permissions';
import { default as VariableSelect } from '../../../common/selectors/Variable';
import Loading from '../../../common/components/Loading';
import { notifyError, notifySuccess } from '../../../utils/notify';
import Form from '../../forms/Form';
import FormFieldSelect from '../../forms/FormFieldSelect';
import usePermissionsRealm from '../../../utils/usePermissionsRealm';
import usePermission from '../../../utils/usePermission';
import Dialog from '../../Dialog';
import Button from '../../../common/components/Button';
import copyToClipboard from '../../../common/utilsClient/copyToClipboard';
import Recipient from '../../../common/models/Recipient';
import Activity from '../../../common/models/Activity';
import Participation from '../../../common/models/Participation';
import { useFormField } from '../../forms/hooks';

const prettifyJSON = (jsonString) => {
  try {
    return JSON.stringify(JSON.parse(jsonString), null, 2);
  } catch {
    return JSON.stringify(JSON.parse('{}'), null, 2);
  }
};

const toChoices = (options, t) => {
  return map(options, ({ value, label }) => {
    return {
      const: value,
      title: t(label),
    };
  });
};

const renderSelect = ({
  // eslint-disable-next-line react/prop-types
  forwardedRef,
  ...props
}) => {
  return (
    <FormFieldSelect
      ref={forwardedRef}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

const INPUT_TYPE__CUSTOM = 'custom';
const INPUT_TYPE__CUSTOM_PII = 'custom_pii';
const INPUT_TYPE__IDENTIFIER = 'identifier';

function getDisplayText(variable) {
  if (variable.isIdentifier()) {
    return `Identifier :: ${variable.getIdentifierNamespace()}`;
  }
  if (variable.isNative()) {
    if (variable.isPII()) {
      return `Native & PII:: ${variable.nativeKey}`;
    }
    return `Native :: ${variable.nativeKey}`;
  }
  if (variable.isPII()) {
    return `Custom & PII :: ${variable._id}`;
  }
  return `Custom :: ${variable._id}`;
}

const SCOPE_NAME_OPTIONS = [
  // {
  //   value: '@project',
  //   label: 'Project',
  // },
  // {
  //   value: '@milestone',
  //   label: 'Milestone',
  // },
  {
    value: Participation.scopeName,
    label: 'forms:resourceType.options.participation',
  },
  {
    value: Activity.scopeName,
    label: 'forms:resourceType.options.activity',
  },
  {
    value: Recipient.scopeName,
    label: 'forms:resourceType.options.patient',
  },
  // {
  //   value: '@answersSheet',
  //   label: 'Answer sheet',
  // },
];

const empty = {};

const EditVariable = ({ variableId, onCancel, onSubmitted, visible }) => {
  const { t } = useTranslation();

  const formFields = {
    inputType: useFormField(INPUT_TYPE__CUSTOM),
  };

  const {
    inputType: {
      input: { value: inputType },
    },
  } = formFields;

  const variableForm = useRef();

  const variable = useSelector(VariableSelect.one().whereIdEquals(variableId));

  const { ready: variableReady } = useDDPSubscription(
    variableId &&
      apiAdminOneVariable.withParams({
        variableId,
      }),
  );

  const allReady = variableReady;

  const { ddpCall, ddpIsPending } = useDDPCall();

  const handleOnSave = useCallback(
    (options) => {
      if (ddpIsPending) {
        return;
      }
      if (variableId) {
        variableForm.current
          .submit()
          .then((formValues) => {
            return ddpCall(
              apiAdminUpdateVariable.withParams({
                variableId,
                ...formValues,
              }),
            )
              .then(() => {
                if (onSubmitted) {
                  return onSubmitted(variableId, options);
                }
                return undefined;
              })
              .then(notifySuccess(t('confirmations:editVariable.success')));
          })
          .then(onCancel)
          .catch(notifyError());
      } else {
        variableForm.current
          .submit()
          .then((formValues) => {
            return ddpCall(
              apiAdminCreateVariable.withParams(
                omitBy(
                  {
                    ...formValues,
                    scopeName:
                      inputType === INPUT_TYPE__IDENTIFIER
                        ? Recipient.scopeName
                        : formValues.scopeName,
                    inputType,
                  },
                  isNil,
                ),
              ),
            )
              .then(({ variableId: newVariableId }) => {
                if (onSubmitted) {
                  return onSubmitted(newVariableId);
                }
                return undefined;
              })
              .then(notifySuccess(t('confirmations:addVariable.success')));
          })
          .then(onCancel)
          .catch(notifyError());
      }
    },
    [
      variableForm,
      variableId,
      onCancel,
      onSubmitted,
      t,
      ddpCall,
      ddpIsPending,
      inputType,
    ],
  );

  const { domains } = usePermissionsRealm([ADMIN_CREATE_VARIABLE]);

  const schema = useMemo(() => {
    const newSchema = {
      type: 'object',
      required: compact([
        'domains',
        'name',
        inputType === INPUT_TYPE__IDENTIFIER ? null : 'scopeName',
        inputType === INPUT_TYPE__IDENTIFIER ? 'idNamespace' : null,
        inputType === INPUT_TYPE__CUSTOM || inputType === INPUT_TYPE__CUSTOM_PII
          ? 'jsonSchema'
          : null,
      ]),
      properties: {
        domains: {
          title: t('forms:belongsTo.label'),
          type: 'array',
          items: {
            type: 'string',
            enum: domains,
          },
        },
        name: {
          title: t('forms:name.label'),
          type: 'string',
        },
        scopeName: {
          title: t('forms:resourceType.label'),
          oneOf: toChoices(SCOPE_NAME_OPTIONS, t),
        },
        idNamespace: {
          title: t('forms:namespace.label'),
          type: 'string',
        },
        jsonSchema: {
          title: t('forms:json.label'),
          type: 'string',
          format: 'json',
        },
      },
    };
    return newSchema;
  }, [t, domains, inputType]);

  const initialValues = useMemo(() => {
    if (!allReady) {
      return null;
    }
    const allVariables = {
      domains: [],
    };
    if (variable) {
      allVariables.domains = variable.getDomains();
      allVariables.scopeName = variable.scopeName;
      allVariables.name = variable.name;
      allVariables.idNamespace = variable.getIdentifierNamespace();
      allVariables.jsonSchema = prettifyJSON(variable.jsonSchema);
    }
    return allVariables;
  }, [allReady, variable]);

  useEffect(() => {
    if (allReady && variable) {
      if (variable.isIdentifier()) {
        formFields.inputType.input.onChange(INPUT_TYPE__IDENTIFIER);
      } else if (variable.isPII()) {
        formFields.inputType.input.onChange(INPUT_TYPE__CUSTOM_PII);
      } else {
        formFields.inputType.input.onChange(INPUT_TYPE__CUSTOM);
      }
    }
  }, [allReady, variable, formFields.inputType.input]);

  const fields = useMemo(() => {
    const newFields = {
      '': {
        children: compact([
          inputType === INPUT_TYPE__IDENTIFIER ? null : 'scopeName',
          'domains',
          'name',
          inputType === INPUT_TYPE__IDENTIFIER ? 'idNamespace' : null,
          'jsonSchema',
        ]),
      },
      scopeName: {
        disabled: !!variableId,
        render: renderSelect,
      },
      idNamespace: {
        disabled: !!variableId,
      },
    };
    return newFields;
  }, [inputType, variableId]);

  const formName = variableId ? `edit_variable_${variableId}` : 'add_variable';
  const canUpdateVariable = usePermission(ADMIN_UPDATE_VARIABLE, {
    relativeTo: variable && variable.getDomains(),
  });
  const confirmLoading = !allReady || ddpIsPending;
  const options = [
    {
      value: INPUT_TYPE__CUSTOM,
      label: t('forms:custom.label'),
    },
    {
      value: INPUT_TYPE__CUSTOM_PII,
      label: t('forms:customAndPII.label'),
    },
    {
      value: INPUT_TYPE__IDENTIFIER,
      label: t('forms:identifier.label'),
    },
  ];

  return (
    <Dialog
      title={variable || !allReady ? t('editVariable') : t('addVariable')}
      size="large"
      onCancel={onCancel}
      visible={visible}
      okText={t('save')}
      onOk={handleOnSave}
      isOkDisabled={!!variableId && !canUpdateVariable}
      loading={confirmLoading}
    >
      <div className="stack-6 w-full sm:w-1/2">
        {!variable && (
          <FormFieldSelect
            data-testid="form-field-bind-to"
            name="inputType"
            label={t('forms:bindTo.label')}
            options={options}
            input={formFields.inputType.input}
            meta={empty}
          />
        )}
        {variable && (
          <div className="flex items-end space-x-4">
            <FormFieldSelect
              data-testid="form-field-bind-to"
              name="inputType"
              label={t('forms:bindTo.label')}
              options={[
                {
                  value: variable._id,
                  label: getDisplayText(variable),
                },
              ]}
              input={{ value: variable._id, onChange: () => {} }}
              disabled
              meta={empty}
            />
            <Button
              type="link"
              icon={<ContentCopy />}
              onClick={() => copyToClipboard(variable._id)}
            />
          </div>
        )}
        <Form
          data-testid="project-profile-form"
          ref={variableForm}
          name={formName}
          initialValues={initialValues}
          schema={schema}
          fields={fields}
        />
        {!allReady && <Loading />}
      </div>
    </Dialog>
  );
};

EditVariable.propTypes = {
  variableId: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmitted: PropTypes.func,
  visible: PropTypes.bool,
};

EditVariable.defaultProps = {
  variableId: null,
  onCancel: null,
  onSubmitted: null,
  visible: true,
};

export default EditVariable;
