// @ts-check
export const EXPECTED_MINIMUM_PROPERTIES_LENGTH =
  'EXPECTED_MINIMUM_PROPERTIES_LENGTH';
export const EXPECTED_MAXIMUM_PROPERTIES_LENGTH =
  'EXPECTED_MAXIMUM_PROPERTIES_LENGTH';
export const EXPECTED_MINIMUM_ITEMS_LENGTH = 'EXPECTED_MINIMUM_ITEMS_LENGTH';
export const EXPECTED_MAXIMUM_ITEMS_LENGTH = 'EXPECTED_MAXIMUM_ITEMS_LENGTH';
export const EXPECTED_REQUIRED_PROPERTY = 'EXPECTED_REQUIRED_PROPERTY';
export const EXPECTED_TYPE = 'EXPECTED_TYPE';
export const EXPECTED_ONE_OF_TYPES = 'EXPECTED_ONE_OF_TYPES';
export const EXPECTED_VALUE_TO_EXIST = 'EXPECTED_VALUE_TO_EXIST';
export const EXPECTED_VALUE_NOT_TO_EXIST = 'EXPECTED_VALUE_NOT_TO_EXIST';
export const EXPECTED_VALUE_TO_BE = 'EXPECTED_VALUE_TO_BE';
export const EXPECTED_VALUE_TO_BE_ONE_OF = 'EXPECTED_VALUE_TO_BE_ONE_OF';
export const EXPECTED_VALUE_GREATER_THAN_OR_EQUAL =
  'EXPECTED_VALUE_GREATER_THAN_OR_EQUAL';
export const EXPECTED_VALUE_LESSER_THAN_OR_EQUAL =
  'EXPECTED_VALUE_LESSER_THAN_OR_EQUAL';
export const EXPECTED_VALUE_GREATER_THAN = 'EXPECTED_VALUE_GREATER_THAN';
export const EXPECTED_VALUE_LESSER_THAN = 'EXPECTED_VALUE_LESSER_THAN';
export const EXPECTED_VALUE_MULTIPLE_OF = 'EXPECTED_VALUE_MULTIPLE_OF';
export const EXPECTED_VALUE_TO_MATCH_PATTERN =
  'EXPECTED_VALUE_TO_MATCH_PATTERN';
export const EXPECTED_VALUE_TO_MATCH_FORMAT = 'EXPECTED_VALUE_TO_MATCH_FORMAT';
export const EXPECTED_LENGTH_GREATER_THAN_OR_EQUAL =
  'EXPECTED_LENGTH_GREATER_THAN_OR_EQUAL';
export const EXPECTED_LENGTH_LESSER_THAN_OR_EQUAL =
  'EXPECTED_LENGTH_LESSER_THAN_OR_EQUAL';
export const EXPECTED_VALUE_NOT_TO_MATCH_SCHEMA =
  'EXPECTED_VALUE_NOT_TO_MATCH_SCHEMA';
export const EXPECTED_VALUE_TO_MATCH_AT_MOST_ONE_SCHEMA =
  'EXPECTED_VALUE_TO_MATCH_AT_MOST_ONE_SCHEMA';
export const EXPECTED_VALUE_TO_MATCH_AT_LEAST_ONE_SCHEMA =
  'EXPECTED_VALUE_TO_MATCH_AT_LEAST_ONE_SCHEMA';
export const INVALID_SCHEMA = 'INVALID_SCHEMA';
export const INVALID_REGULAR_EXPRESSION = 'INVALID_REGULAR_EXPRESSION';
export const INVALID_REFERENCE = 'INVALID_REFERENCE';
export const INVALID_TYPE = 'INVALID_TYPE';
export const INVALID_BSON_TYPE = 'INVALID_BSON_TYPE';

/**
 * @typedef {(
 *   | typeof EXPECTED_MINIMUM_PROPERTIES_LENGTH
 *   | typeof EXPECTED_MAXIMUM_PROPERTIES_LENGTH
 *   | typeof EXPECTED_MINIMUM_ITEMS_LENGTH
 *   | typeof EXPECTED_MAXIMUM_ITEMS_LENGTH
 *   | typeof EXPECTED_REQUIRED_PROPERTY
 *   | typeof EXPECTED_TYPE
 *   | typeof EXPECTED_ONE_OF_TYPES
 *   | typeof EXPECTED_VALUE_TO_EXIST
 *   | typeof EXPECTED_VALUE_NOT_TO_EXIST
 *   | typeof EXPECTED_VALUE_TO_BE
 *   | typeof EXPECTED_VALUE_TO_BE_ONE_OF
 *   | typeof EXPECTED_VALUE_GREATER_THAN_OR_EQUAL
 *   | typeof EXPECTED_VALUE_LESSER_THAN_OR_EQUAL
 *   | typeof EXPECTED_VALUE_GREATER_THAN
 *   | typeof EXPECTED_VALUE_LESSER_THAN
 *   | typeof EXPECTED_VALUE_MULTIPLE_OF
 *   | typeof EXPECTED_VALUE_TO_MATCH_PATTERN
 *   | typeof EXPECTED_VALUE_TO_MATCH_FORMAT
 *   | typeof EXPECTED_LENGTH_GREATER_THAN_OR_EQUAL
 *   | typeof EXPECTED_LENGTH_LESSER_THAN_OR_EQUAL
 *   | typeof EXPECTED_VALUE_NOT_TO_MATCH_SCHEMA
 *   | typeof EXPECTED_VALUE_TO_MATCH_AT_MOST_ONE_SCHEMA
 *   | typeof EXPECTED_VALUE_TO_MATCH_AT_LEAST_ONE_SCHEMA
 *   | typeof INVALID_SCHEMA
 *   | typeof INVALID_REGULAR_EXPRESSION
 *   | typeof INVALID_REFERENCE
 *   | typeof INVALID_TYPE
 *   | typeof INVALID_BSON_TYPE
 * )} ErrorType
 */

/**
 * @typedef {object} ErrorProperties
 * @property {ErrorType} type
 * @property {boolean | object} schema
 * @property {string} [key]
 * @property {string} [expected]
 * @property {unknown} [value]
 * @returns {string}
 */

/**
 * @param {string} char
 * @returns {boolean}
 */
function isVowel(char) {
  return 'aeiou'.indexOf(char.toLowerCase()) >= 0;
}

/**
 * @param {string | undefined} format
 * @returns {string}
 */
export function getErrorMessageForFormat(format) {
  switch (format) {
    case 'decimal': {
      return 'Expected decimal number';
    }
    case 'instant': {
      return 'Expected timestamp';
    }
    case 'partial-date': {
      return 'Expected date or partial date';
    }
    case 'date-time': {
      return 'Expected date and time of day';
    }
    case 'time': {
      return 'Expected time of day';
    }
    case 'year': {
      return 'Expected year';
    }
    case 'date': {
      return 'Expected full date';
    }
    case 'email': {
      return 'Expected valid email';
    }
    case 'phone': {
      return 'Expected up to 14 digits prefixed with "+" and country code & no 0 after +61, +64 and +65';
    }
    case 'regex': {
      return 'Expected valid ECMA 262 regular expression';
    }
    default: {
      if (format) {
        return `Expected value to match unknown format: ${format}`;
      }
      return 'Expected value to match unknown format';
    }
  }
}

/**
 * @param {ErrorProperties} props
 * @returns {string}
 */
export default function defaultRenderErrorMessage(props) {
  switch (props.type) {
    case EXPECTED_MINIMUM_PROPERTIES_LENGTH: {
      return `Expected at least ${props.expected} properties`;
    }
    case EXPECTED_MAXIMUM_PROPERTIES_LENGTH: {
      return `Expected at most ${props.expected} properties`;
    }
    case EXPECTED_MINIMUM_ITEMS_LENGTH: {
      return `Expected at least ${props.expected} item(s)`;
    }
    case EXPECTED_MAXIMUM_ITEMS_LENGTH: {
      return `Expected at most ${props.expected} item(s)`;
    }
    case EXPECTED_REQUIRED_PROPERTY: {
      return `Missing required property "${props.key}"`;
    }
    case EXPECTED_VALUE_TO_EXIST: {
      return 'Expected value to exist';
    }
    case EXPECTED_TYPE: {
      if (props.expected && isVowel(props.expected.charAt(0))) {
        return `Value is not an ${props.expected}`;
      }
      return `Value is not a ${props.expected}`;
    }
    case EXPECTED_ONE_OF_TYPES: {
      return `Value should be one of type: ${props.expected}`;
    }
    case EXPECTED_VALUE_TO_BE: {
      return `Value is not equal to ${props.expected}`;
    }
    case EXPECTED_VALUE_TO_BE_ONE_OF: {
      return `Value should be one of: ${props.expected}`;
    }
    case EXPECTED_VALUE_GREATER_THAN_OR_EQUAL: {
      return `Expected value at least ${props.expected}`;
    }
    case EXPECTED_VALUE_LESSER_THAN_OR_EQUAL: {
      return `Expected value at most ${props.expected}`;
    }
    case EXPECTED_VALUE_GREATER_THAN: {
      return `Value should be greater than ${props.expected}`;
    }
    case EXPECTED_VALUE_LESSER_THAN: {
      return `Value should be lesser than ${props.expected}`;
    }
    case EXPECTED_VALUE_MULTIPLE_OF: {
      return `Value should be multiple of ${props.expected}`;
    }
    case EXPECTED_VALUE_NOT_TO_EXIST: {
      return 'No value is accepted';
    }
    case EXPECTED_VALUE_TO_MATCH_PATTERN: {
      return `Value should be of the form: ${props.expected}`;
    }
    case EXPECTED_VALUE_TO_MATCH_FORMAT: {
      return getErrorMessageForFormat(props.expected);
    }
    case EXPECTED_LENGTH_GREATER_THAN_OR_EQUAL: {
      return `Expected length at least ${props.expected}`;
    }
    case EXPECTED_LENGTH_LESSER_THAN_OR_EQUAL: {
      return `Expected length at most ${props.expected}`;
    }
    case EXPECTED_VALUE_NOT_TO_MATCH_SCHEMA: {
      return 'Expected value not to match schema';
    }
    case EXPECTED_VALUE_TO_MATCH_AT_MOST_ONE_SCHEMA: {
      return 'Expected value to match at most one schema';
    }
    case EXPECTED_VALUE_TO_MATCH_AT_LEAST_ONE_SCHEMA: {
      return 'Expected value to match at least one schema';
    }
    case INVALID_SCHEMA: {
      return 'Expected value to satisfy an unknown constraint';
    }
    case INVALID_REGULAR_EXPRESSION: {
      return 'Invalid regular expression';
    }
    case INVALID_REFERENCE: {
      return 'Invalid reference';
    }
    case INVALID_TYPE: {
      return `Invalid type: ${props.expected}`;
    }
    case INVALID_BSON_TYPE: {
      return `Invalid type: ${props.expected}`;
    }
    default: {
      return 'Invalid value';
    }
  }
}
