import React, { useState, useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { PropTypesInput, PropTypesMeta } from '@zedoc/react-questionnaire';
import { useTranslation } from 'react-i18next';
import useValidateStatus from '../../utils/useValidateStatus';
import Input from '../inputs/Input';
import FormFieldWrapper from './FormFieldWrapper';
import Select from '../Select';
import InputDate from '../../common/components/InputDate';

const MODE_FULL = 'yyyy-mm-dd';
const MODE_PARTIAL = 'yyyy-mm';
const MODE_YEAR = 'yyyy';

const FormFieldPartialDate = ({
  label,
  input,
  meta,
  required,
  disabled,
  // 'data-testid': testId,
}) => {
  const { t } = useTranslation('forms');
  const { value, onChange } = input;
  const validateStatus = useValidateStatus({ meta });

  const [currentMode, setCurrentMode] = useState('yyyy-mm-dd');

  const modes = [
    {
      value: MODE_FULL,
      label: t('partialDate.modes.full'),
    },
    {
      value: MODE_PARTIAL,
      label: t('partialDate.modes.partial'),
    },
    {
      value: MODE_YEAR,
      label: t('partialDate.modes.year'),
    },
  ];

  const handleModeChange = (mode) => {
    onChange('');
    setCurrentMode(mode);
  };

  const hasYearMonthDay = (dateString) => {
    // Regular expression to match YYYY-MM-DD format
    const regex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/;

    // Check if the date string matches the regex
    return regex.test(dateString);
  };

  const hasOnlyYearAndMonth = (dateString) => {
    // Regular expression to match YYYY-MM format
    const regex = /^\d{4}-(0[1-9]|1[0-2])$/;

    // Check if the date string matches the regex
    return regex.test(dateString);
  };

  const hasOnlyYear = (dateString) => {
    // Regular expression to match YYYY format
    const regex = /^\d{4}$/;

    // Check if the date string matches the regex
    return regex.test(dateString);
  };

  useEffect(() => {
    if (hasYearMonthDay(value)) {
      setCurrentMode(MODE_FULL);
    } else if (hasOnlyYearAndMonth(value)) {
      setCurrentMode(MODE_PARTIAL);
    } else if (hasOnlyYear(value)) {
      setCurrentMode(MODE_YEAR);
    }
  }, [value]);

  const partialDateFormattedValue = useMemo(() => {
    const regex = /^(\d{4})-(\d{2})$/;
    const match = value?.match(regex);

    if (match) {
      return value.replace(regex, '$2/$1');
    }

    return value;
  }, [value]);
  const handleOnPartialDateChange = useCallback(
    (str) => {
      const regex = /^(\d{2})\/(\d{4})$/;
      const match = str.match(regex);

      if (match) {
        const [, month, year] = match;

        onChange(`${year}-${month}`);
      } else {
        onChange(str);
      }
    },
    [onChange],
  );

  const widget = useMemo(() => {
    switch (currentMode) {
      case MODE_FULL:
        return (
          <InputDate onChange={onChange} value={value} disabled={disabled} />
        );
      case MODE_PARTIAL:
        return (
          <Input
            value={partialDateFormattedValue}
            onChange={(event) => handleOnPartialDateChange(event.target.value)}
            placeholder="MM/YYYY"
            validateStatus={validateStatus}
          />
        );
      case MODE_YEAR:
        return (
          <Input
            value={value}
            onChange={onChange}
            placeholder="YYYY"
            validateStatus={validateStatus}
          />
        );
      default:
        return null;
    }
  }, [
    currentMode,
    value,
    onChange,
    disabled,
    validateStatus,
    partialDateFormattedValue,
    handleOnPartialDateChange,
  ]);

  return (
    <FormFieldWrapper
      label={label}
      required={required}
      meta={meta}
      disabled={disabled}
    >
      <div className="cluster-4">
        {widget}
        <div className="flex-shrink-0">
          <Select
            value={currentMode}
            options={modes}
            onChange={handleModeChange}
          />
        </div>
      </div>
    </FormFieldWrapper>
  );
};

FormFieldPartialDate.propTypes = {
  // 'data-testid': PropTypes.string,
  label: PropTypes.string,
  input: PropTypesInput,
  meta: PropTypesMeta,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

FormFieldPartialDate.defaultProps = {
  // 'data-testid': null,
  label: null,
  input: null,
  meta: null,
  required: false,
  disabled: false,
};

export default FormFieldPartialDate;
