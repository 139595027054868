/* eslint-disable react/prop-types */
import React from 'react';
import { components } from 'react-select';

const Input = ({ selectProps, ...rest }) => {
  const { testId } = selectProps;

  return (
    <components.Input
      data-testid={`${testId}-input`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    />
  );
};

export default Input;
