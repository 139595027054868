import includes from 'lodash/includes';
import compact from 'lodash/compact';
import { useMemo } from 'react';
import map from 'lodash/map';
import forEach from 'lodash/forEach';
// import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDDPSubscription } from '@zedoc/ddp-connector';
import { apiZedocOneProject } from '../common/api/zedoc';
import { apiAnalyticsDashboardProgram } from '../common/api/analytics';
import { default as ProjectSelect } from '../common/selectors/Project';
import { default as ProjectDashboardSelect } from '../common/selectors/ProjectDashboard';
import {
  FILTER_CONDITION__DATE_EQUALS,
  FILTER_CONDITION__DATE_NOT_EQUAL,
  FILTER_CONDITION__DATE_AFTER,
  FILTER_CONDITION__DATE_BEFORE,
  FILTER_CONDITION__DATE_SAME_OR_AFTER,
  FILTER_CONDITION__DATE_SAME_OR_BEFORE,
} from '../common/constants';
import { makePresets } from './useProjectFilters';

const useAnalyticsFilters = ({ dashboardId, defaultFilters }) => {
  // const {
  //   i18n: { language },
  // } = useTranslation();

  const { ready: dashboardReady } = useDDPSubscription(
    dashboardId &&
      apiAnalyticsDashboardProgram.withParams({
        dashboardId,
      }),
  );

  const dashboard = useSelector(
    ProjectDashboardSelect.one().whereIdEquals(dashboardId),
  );
  const projectId = dashboard && dashboard.projectId;

  const { ready: projectReady } = useDDPSubscription(
    projectId &&
      apiZedocOneProject.withParams({
        projectId,
      }),
  );

  const project = useSelector(ProjectSelect.one().whereIdEquals(projectId));
  const timezone = project && project.getTimezone();
  const empty = useMemo(() => [], []);

  const presets = useMemo(() => {
    const allPresets = compact(
      map(defaultFilters, (preset) => {
        if (
          preset.meta &&
          preset.meta.perspectives &&
          !includes(preset.meta.perspectives, dashboard?.program?.perspective)
        ) {
          return null;
        }
        switch (preset.condition) {
          case FILTER_CONDITION__DATE_EQUALS:
          case FILTER_CONDITION__DATE_NOT_EQUAL:
          case FILTER_CONDITION__DATE_AFTER:
          case FILTER_CONDITION__DATE_BEFORE:
          case FILTER_CONDITION__DATE_SAME_OR_AFTER:
          case FILTER_CONDITION__DATE_SAME_OR_BEFORE:
            return {
              ...preset,
              state: {
                ...preset.state,
                timezone,
                threshold: project && project.getCurrentYearMonthDay(),
              },
            };
          default:
            return preset;
        }
      }),
    );

    forEach(
      dashboard?.program?.variables?.outputs,
      ({ name, namespace, schema }) => {
        if (schema && schema.type) {
          makePresets(`${namespace || ''}:${name}`, name, schema).forEach(
            (preset) => {
              allPresets.push(preset);
            },
          );
        }
      },
    );
    return allPresets;
  }, [project, dashboard, timezone, defaultFilters]);

  if (!projectReady || !dashboardReady) {
    return empty;
  }

  return presets;
};

export default useAnalyticsFilters;
