import CurrentUser from '../models/CurrentUser';
import { USER_SESSION_KEEP_ALIVE_SECONDS } from '../common/constants';

function resumeTokenKeepAlive() {
  return (store) => {
    let loggedIn = false;
    let hadActivity = false;
    let keepaliveInterval = null;
    let keepalivePromise = null;
    let timelockCheckInterval = null;

    const stopKeepaliveInterval = () => {
      if (keepaliveInterval) {
        clearInterval(keepaliveInterval);
        clearInterval(timelockCheckInterval);
        keepaliveInterval = null;
        timelockCheckInterval = null;
      }
      hadActivity = false;
    };

    const keepaliveTick = () => {
      if (!keepalivePromise) {
        if (!hadActivity) {
          stopKeepaliveInterval();
        }
        hadActivity = false;
        keepalivePromise = store
          .dispatch(CurrentUser.action.resumeTokenKeepAlive())
          .then(() => {
            keepalivePromise = null;
          })
          .catch((err) => {
            keepalivePromise = null;
            console.error(err);
          });
      }
    };

    const ensureKeepaliveInterval = () => {
      hadActivity = true;
      if (!keepaliveInterval) {
        keepaliveTick();
        keepaliveInterval = setInterval(
          () => keepaliveTick(),
          1000 * USER_SESSION_KEEP_ALIVE_SECONDS,
        );
      }
    };

    const ensureLoggedIn = () => {
      if (!loggedIn) {
        loggedIn = true;
        if (window) {
          window.addEventListener('click', ensureKeepaliveInterval);
          window.addEventListener('keydown', ensureKeepaliveInterval);
        }
      }
    };

    const startTimelockCheckInterval = (state) => {
      clearInterval(timelockCheckInterval);
      timelockCheckInterval = setInterval(function () {
        if (!!CurrentUser.select.isLockedInTimeWindow()(state)) {
          clearInterval(timelockCheckInterval);
          store.dispatch(CurrentUser.action.logoutCurrentUser());
        }
      }, 5000);
    };

    const ensureLoggedOut = () => {
      stopKeepaliveInterval();
      if (loggedIn) {
        loggedIn = false;
        if (window) {
          window.removeEventListener('click', ensureKeepaliveInterval);
          window.removeEventListener('keydown', ensureKeepaliveInterval);
        }
      }
    };

    return (next) => (action) => {
      const state = store.getState();
      const isLoggedIn = CurrentUser.select.isLoggedIn()(state);
      if (isLoggedIn) {
        ensureLoggedIn();
        startTimelockCheckInterval(state);
      } else {
        ensureLoggedOut();
      }
      return next(action);
    };
  };
}

export default resumeTokenKeepAlive;
