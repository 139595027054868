"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toDateString = exports.isDateString = void 0;
const isValidDate_1 = require("./isValidDate");
const dateRegEx = /^\d{4}-\d{2}-\d{2}/;
const isDateString = (isoString) => {
    return (dateRegEx.test(isoString) &&
        isoString.length === 10 &&
        (0, isValidDate_1.isValidDate)(isoString));
};
exports.isDateString = isDateString;
const toDateString = (isoString) => {
    if (!dateRegEx.test(isoString) || !(0, isValidDate_1.isValidDate)(isoString)) {
        throw new Error('Expected a date string in the format YYYY-MM-DD');
    }
    return isoString.substring(0, 10);
};
exports.toDateString = toDateString;
