"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toTimeZone = exports.isTimeZone = void 0;
const isTimeZone = (str) => {
    try {
        Intl.DateTimeFormat(undefined, { timeZone: str });
        return true;
    }
    catch (e) {
        return false;
    }
};
exports.isTimeZone = isTimeZone;
const toTimeZone = (str) => {
    if (!(0, exports.isTimeZone)(str)) {
        throw new Error('Expected a valid timezone name');
    }
    return str;
};
exports.toTimeZone = toTimeZone;
