"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isValidDate = exports.daysInMonth = void 0;
/**
 * See: https://stackoverflow.com/a/1433119/2817257
 * @param m month, indexed from 1 to 12
 * @param y year
 */
function daysInMonth(m, y) {
    switch (m) {
        case 2:
            return (y % 4 === 0 && y % 100) || y % 400 === 0 ? 29 : 28;
        case 4:
        case 6:
        case 9:
        case 11:
            return 30;
        default:
            return 31;
    }
}
exports.daysInMonth = daysInMonth;
/**
 * @param isoString
 */
const isValidDate = (isoString) => {
    const parts = isoString.substring(0, 10).split('-');
    if (parts.length === 0 || parts.length > 3) {
        return false;
    }
    if (parts.length >= 2 && parts[1].length !== 2) {
        return false;
    }
    if (parts.length >= 3 && parts[2].length !== 2) {
        return false;
    }
    const [year, month, day] = parts.map((value) => +value);
    switch (parts.length) {
        case 1: {
            if (Number.isNaN(year)) {
                return false;
            }
            return true;
        }
        case 2: {
            if (Number.isNaN(year) || Number.isNaN(month)) {
                return false;
            }
            return month >= 1 && month <= 12;
        }
        case 3: {
            if (Number.isNaN(year) || Number.isNaN(month) || Number.isNaN(day)) {
                return false;
            }
            return (month >= 1 && month <= 12 && day > 0 && day <= daysInMonth(month, year));
        }
        default: {
            return false;
        }
    }
};
exports.isValidDate = isValidDate;
