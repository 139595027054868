import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { yearMonthDayShift } from '@zedoc/date';
import { PropTypesInput, PropTypesMeta } from '@zedoc/react-questionnaire';
import InputDate from './InputDate';
import FormFieldWrapper from './FormFieldWrapper';

const FormFieldDate = ({
  label,
  required,
  extra,
  meta,
  input: { value, onChange },
  disabled,
  plusDays,
  autoFocus,
  min,
  max,
  'data-testid': testId,
}) => {
  const handleOnChange = useCallback(
    (newValue) => {
      if (onChange) {
        if (plusDays) {
          onChange(yearMonthDayShift(newValue, plusDays));
        } else {
          onChange(newValue);
        }
      }
    },
    [onChange, plusDays],
  );
  return (
    <FormFieldWrapper
      label={label}
      required={required}
      disabled={disabled}
      meta={meta}
      extra={extra}
    >
      <InputDate
        onChange={handleOnChange}
        value={plusDays ? yearMonthDayShift(value, -plusDays) : value}
        disabled={disabled}
        autoFocus={autoFocus}
        data-testid={testId}
        min={min}
        max={max}
      />
    </FormFieldWrapper>
  );
};

FormFieldDate.propTypes = {
  input: PropTypesInput.isRequired,
  meta: PropTypesMeta.isRequired,
  extra: PropTypes.node,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  plusDays: PropTypes.number,
  required: PropTypes.bool,
  autoFocus: PropTypes.bool,
  'data-testid': PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
};

FormFieldDate.defaultProps = {
  label: '',
  extra: null,
  disabled: false,
  plusDays: 0,
  required: false,
  autoFocus: false,
  'data-testid': null,
  min: null,
  max: null,
};

export default FormFieldDate;
