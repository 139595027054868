/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { components } from 'react-select';
import { InputCheckbox } from '../../common/components/Checkbox';

const Option = ({
  isDisabled,
  isSelected,
  label,
  value,
  isMulti,
  innerProps,
  selectProps,
  ...rest
}) => {
  // const { testId } = selectProps;

  // eslint-disable-next-line no-nested-ternary
  return !isDisabled ? (
    isMulti ? (
      // NOTE: "isSelected" is not passed to "components.Option" on purpose to not to trigger "is-selected" styling
      <components.Option
        innerProps={{
          ...innerProps,
          // 'data-testid': `${testId}-option-${label}`,
          // TODO: For now, using semi-hardcoded test ids to support current e2e tests
          'data-testid': `select-option-${label}`,
        }}
        {...rest}
      >
        <div className="cluster-2 items-center">
          <InputCheckbox checked={isSelected} />
          {label}
        </div>
      </components.Option>
    ) : (
      <components.Option
        isSelected={isSelected}
        innerProps={{
          ...innerProps,
          // 'data-testid': `${testId}-option-${label}`,
          // TODO: For now, using semi-hardcoded test ids to support current e2e tests
          'data-testid': `select-option-${label}`,
        }}
        {...rest}
      />
    )
  ) : null;
};

export default Option;
