const flatten = (arr) => {
  return arr.reduce(
    (acc, val) =>
      Array.isArray(val.options)
        ? acc.concat(flatten(val.options))
        : acc.concat(val),
    [],
  );
};

// eslint-disable-next-line import/prefer-default-export
export const getValue = (opts, val, getOptVal, isMulti, isCreatable) => {
  if (val === undefined) return undefined;

  let options = flatten(opts);

  // NOTE: It usually means that selected value is not present in options so we make sure it is
  if (val && isCreatable) {
    if (isMulti) {
      options = [...options, ...val.map((value) => ({ value, label: value }))];
    } else {
      options = [...options, { value: val, label: val }];
    }
  }

  if (val && isMulti) {
    return val.map((v) => options.find((o) => getOptVal(o) === v));
  }

  return options.find((o) => getOptVal(o) === val);
};
