import flatMap from 'lodash/flatMap';
import { createSelector } from 'reselect';
import { toSelector, reconcilingSelector } from '@zedoc/selectors';
import { createCurrentUserSelectors } from '@zedoc/ddp-connector';
import { EJSON } from '@zedoc/ejson';
import {
  apiCurrentUserResumeTokenKeepAlive,
  forgotPassword,
  DDP_COLLECTION_CURRENT_USER,
} from '../common/api/currentUser';
import CurrentUserRole from './CurrentUserRole';
import CurrentUserGroup from './CurrentUserGroup';
import User from '../common/models/User';
import { callMethod } from '../common/utilsClient/ddp/actions';
import { getResumeToken } from '../common/utilsClient/ddp/selectors';
import { push } from 'connected-react-router';

class CurrentUser extends User {}

CurrentUser.collection = DDP_COLLECTION_CURRENT_USER;
CurrentUser.select = createCurrentUserSelectors(CurrentUser.collection, {
  Model: CurrentUser,
  transform: (doc) => EJSON.fromJSONValue(doc),
});

const constant = (x) => () => x;

const getCurrentPermissionsValidator = (getOptions) =>
  createSelector(
    CurrentUser.select.user(),
    CurrentUserRole.select.all().byId(),
    CurrentUserGroup.select.all().byId(),
    toSelector(getOptions),
    (user, rolesDB, groupsDB, options) =>
      user
        ? user.getPermissionsValidator({
            rolesDB,
            groupsDB,
            ...options,
          })
        : constant(null),
  );

const getAllRolesIds = () =>
  reconcilingSelector(
    CurrentUserGroup.select.all().byId(),
    CurrentUser.select.user(),
    (groupsDB, user) => {
      if (!groupsDB || !user) {
        return [];
      }
      const { groups, roles } = user;
      return [
        ...flatMap(roles, 'id'),
        ...flatMap(groups, (group) =>
          flatMap(groupsDB[group?.id]?.roles, 'id'),
        ),
      ].sort();
    },
  );

const getPermissionsRealm = (getPermissions, getOptions) =>
  createSelector(
    CurrentUser.select.user(),
    CurrentUserRole.select.all().byId(),
    CurrentUserGroup.select.all().byId(),
    toSelector(getPermissions),
    toSelector(getOptions),
    (user, rolesDB, groupsDB, permissions, options) =>
      user
        ? user.getPermissionsRealm(permissions, {
            rolesDB,
            groupsDB,
            ...options,
          })
        : constant(null),
  );

const getPermissionGrant = (getPermissions, getOptions) =>
  createSelector(
    getCurrentPermissionsValidator(getOptions),
    toSelector(getPermissions),
    (validator, permissions) => (validator ? validator(permissions) : null),
  );

const hasPermission = (getPermissions, getOptions) =>
  createSelector(
    getPermissionGrant(getPermissions, getOptions),
    (permissionGrant) => !!permissionGrant,
  );

const isOnboarded = () =>
  createSelector(
    CurrentUser.select.user(),
    (user) => !!(user && user.onboarded),
  );

const isLoggedIn = () =>
  createSelector(
    CurrentUser.select.userId(),
    CurrentUser.select.isLoggingIn(),
    (userId, isLoggingIn) => !!userId && !isLoggingIn,
  );

const isLockedInTimeWindow = () =>
  createSelector(CurrentUser.select.user(), (user) => {
    if (user && user.lockedInTimeWindow && user.isLockedInTimeWindow()) {
      return true;
    }
    return false;
  });

Object.assign(CurrentUser.select, {
  getCurrentPermissionsValidator,
  getAllRolesIds,
  getPermissionsRealm,
  getPermissionGrant,
  hasPermission,
  isOnboarded,
  isLoggedIn,
  getResumeToken,
  isLockedInTimeWindow,
});

const sendPasswordResetEmail = (emailOrNothing) => (dispatch, getState) => {
  let email = emailOrNothing;
  if (emailOrNothing) {
    email = emailOrNothing;
  } else {
    const user = CurrentUser.select.user()(getState());
    if (user) {
      email = user.getEmailAddress();
    }
  }
  return dispatch(
    callMethod(forgotPassword, {
      email,
    }),
  );
};

const resumeTokenKeepAlive = () => (dispatch, getState) => {
  const state = getState();
  const resumeToken = getResumeToken(state);
  const loggedIn = CurrentUser.select.isLoggedIn()(state);
  if (loggedIn && resumeToken) {
    return dispatch(
      callMethod(apiCurrentUserResumeTokenKeepAlive, {
        resumeToken,
      }),
      // eslint-disable-next-line no-console
    ).catch((err) => console.error(err));
  }
  return Promise.resolve();
};

const logoutCurrentUser =
  () =>
  (dispatch, getState, { ddpConnector }) => {
    dispatch(push('/entry/signIn?session_expired=true'));
    return ddpConnector
      .logout()
      .then(() => {
        // console.log("User logged out successfully");
        // You can also dispatch additional actions here if needed
      })
      .catch((error) => {
        //console.error("Logout failed:", error);
        // Handle logout error, e.g., dispatch an error action or show a notification
      });
  };

CurrentUser.action = {
  sendPasswordResetEmail,
  resumeTokenKeepAlive,
  logoutCurrentUser,
};

export default CurrentUser;
