import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import PatientRecord from '../../../../../common/models/PatientRecord';
import {
  VARIABLE_ID__PARTICIPATION_EMR_SYNC,
  VARIABLE_ID__BIRTH_DATE,
  VARIABLE_ID__BIRTH_YEAR,
  VARIABLE_ID__NAME,
  VARIABLE_ID__NAME_FIRST,
  VARIABLE_ID__NAME_LAST,
  VARIABLE_ID__MRN,
} from '../../../../../common/constants';
import Tooltip from '../../../../../common/components/Tooltip';
import useElementTruncated from '../../../../../utils/useElementTruncated';
import OverflowActions from '../../../../../components/OverflowActions';
import DashboardVariableItem from '../DashboardVariableItem';
import { getVariable, labels } from '../helpers';
import useProjectVariablesGender from '../../../../../utils/useProjectVariablesGender';
import useDateTimeFormat from '../../../../../utils/useDateTimeFormat';

const PatientColumn = ({ record, onPatientModalOpen }) => {
  const { ref, isElementTruncated } = useElementTruncated();
  const name =
    getVariable(record.variables, VARIABLE_ID__NAME) ||
    getVariable(record.variables, [
      VARIABLE_ID__NAME_FIRST,
      VARIABLE_ID__NAME_LAST,
    ]);
  const birth =
    getVariable(record.variables, VARIABLE_ID__BIRTH_DATE) ||
    getVariable(record.variables, VARIABLE_ID__BIRTH_YEAR);

  const patientRecord = new PatientRecord(record);
  const gender = useProjectVariablesGender(patientRecord);
  const birthDate = useDateTimeFormat({
    date: moment(birth).toDate(),
    options: { dateStyle: 'medium' },
  });

  return (
    <>
      {name && (
        <Tooltip title={isElementTruncated ? name : ''}>
          <OverflowActions.CopyToClipboard value={name}>
            <button
              type="button"
              onClick={onPatientModalOpen}
              className="max-w-full hover:underline font-medium"
            >
              <span ref={ref} className="truncate block">
                {name}
              </span>
            </button>
          </OverflowActions.CopyToClipboard>
        </Tooltip>
      )}
      <DashboardVariableItem
        label={labels[VARIABLE_ID__PARTICIPATION_EMR_SYNC]}
        value={getVariable(
          record.variables,
          VARIABLE_ID__PARTICIPATION_EMR_SYNC,
        )}
      />
      {patientRecord.getMRN() && (
        <DashboardVariableItem
          label={labels[VARIABLE_ID__MRN]}
          value={patientRecord.getMRN().join(', ')}
        />
      )}
      <div className="cluster-1 items-center">
        {gender}
        {birth && (
          <>
            <span>&bull;</span>
            <span>{birthDate}</span>
          </>
        )}
      </div>
    </>
  );
};

PatientColumn.propTypes = {
  record: PropTypes.instanceOf(PatientRecord).isRequired,
  onPatientModalOpen: PropTypes.func,
};

PatientColumn.defaultProps = {
  onPatientModalOpen: () => {},
};

export default PatientColumn;
