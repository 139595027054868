import map from 'lodash/map';
import get from 'lodash/get';
// import set from 'lodash/set';
import has from 'lodash/has';
import find from 'lodash/find';
import indexOf from 'lodash/indexOf';
import includes from 'lodash/includes';
import some from 'lodash/some';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import isDate from 'lodash/isDate';
import mapValues from 'lodash/mapValues';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import isPlainObject from 'lodash/isPlainObject';
import omitBy from 'lodash/omitBy';
import omit from 'lodash/omit';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import isNil from 'lodash/isNil';
import range from 'lodash/range';
import { checkSchema, isAtomic, cleanValue } from '@zedoc/check-schema';
import {
  toDateFromHoursMinutesSeconds,
  toIntlDateTimeFormat,
} from '@zedoc/date';
import findIndex from 'lodash/findIndex';
import BaseModel from './BaseModel';
import Activity from './Activity';
import Recipient from './Recipient';
import Participation from './Participation';
import setValue, { deleteKey, isRequiredKeyError } from '../utils/setValue';
import csvEncode from '../utils/csvEncode';
import { DOMAIN_PREFIX } from '../constants';
import getUpdatedOwnership, {
  toOwnership,
} from '../permissions/getUpdatedOwnership';

const getKey = (target, path, options = {}) => {
  const { arrayFilters } = options;
  const parts = path.split('.');
  const index = indexOf(parts, '$');
  if (index < 0) {
    return path;
  }
  const key = parts.slice(0, index).join('.');
  const array = get(target, key);
  if (isArray(array) && arrayFilters && arrayFilters[0]) {
    const i = findIndex(array, arrayFilters[0]);
    if (index === parts.length - 1) {
      return `${key}.${i}`;
    }
    const nextKey = getKey(array[i], parts.slice(index + 1).join('.'), {
      ...options,
      arrayFilters: arrayFilters.slice(1),
    });
    if (nextKey) {
      return `${key}.${i}.${nextKey}`;
    }
  }
  return undefined;
};

const getValueWithArrayFilters = (target, path, arrayFilters) => {
  const parts = path.split('.');
  const index = indexOf(parts, '$');
  if (index >= 0) {
    const array = get(target, parts.slice(0, index).join('.'));
    if (isArray(array) && arrayFilters[0]) {
      const item = find(array, arrayFilters[0]);
      if (index === parts.length - 1) {
        return item;
      }
      return getValueWithArrayFilters(
        item,
        parts.slice(index + 1).join('.'),
        arrayFilters.slice(1),
      );
    }
    return undefined;
  }
  return get(target, path);
};

const getSelectorWithArrayFilters = (value, path, arrayFilters) => {
  const parts = path.split('.');
  const index = indexOf(parts, '$');
  if (index >= 0) {
    if (arrayFilters[0]) {
      const key = parts.slice(0, index).join('.');
      if (index === parts.length - 1) {
        return {
          [key]: {
            $elemMatch: arrayFilters[0],
          },
        };
      }
      const selector = getSelectorWithArrayFilters(
        value,
        parts.slice(index + 1).join('.'),
        arrayFilters.slice(1),
      );
      return {
        [key]: {
          $elemMatch: {
            ...arrayFilters[0],
            ...selector,
          },
        },
      };
    }
    return undefined;
  }
  return {
    [path]: value,
  };
};

const getDisplayValueDate = (format, value) => {
  switch (format) {
    case 'time':
      return toDateFromHoursMinutesSeconds(value);
    default:
      return value;
  }
};

const getDisplayValueDateFormatOptions = (format) => {
  switch (format) {
    case 'date': {
      return {
        dateStyle: 'short',
      };
    }
    case 'partial-date': {
      return {
        year: 'numeric',
        month: 'numeric',
      };
    }
    case 'time':
      return {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      };
    case 'year':
      return {
        year: 'numeric',
      };
    default:
      return {};
  }
};

class Variable extends BaseModel {
  getDomains() {
    return map(this.ownership, 'domain');
  }

  isIdentifier() {
    return this.nativeKey === 'identifiers.$.value';
  }

  isPatientName() {
    return this.isPatient() && this.nativeKey === 'name.text';
  }

  isPatientLanguage() {
    return this.isPatient() && this.nativeKey === 'languagePreference';
  }

  isPatientGender() {
    return this.isPatient() && this.nativeKey === 'gender';
  }

  isParticipationStudyNo() {
    return this.isParticipation() && this.nativeKey === 'studyNo';
  }

  isParticipationTrackId() {
    return this.isParticipation() && this.nativeKey === 'trackId';
  }

  isParticipationTrackDate() {
    return this.isParticipation() && this.nativeKey === 'trackDate';
  }

  isParticipationState() {
    return this.isParticipation() && this.nativeKey === 'state';
  }

  isActivityState() {
    return this.isActivity() && this.nativeKey === 'state';
  }

  isActivityMilestoneId() {
    return this.isActivity() && this.nativeKey === 'milestoneId';
  }

  isPII() {
    if (this.pii) {
      return true;
    }
    if (this.nativeKey) {
      const parts = this.nativeKey.split('.').filter((part) => part !== '$');
      /** @type {string[]} */
      const possibleKeys = [];
      for (let i = 1; i <= parts.length; i += 1) {
        possibleKeys.push(parts.slice(0, i).join('.'));
      }
      switch (this.scopeName) {
        case Recipient.scopeName:
          return some(possibleKeys, (key) =>
            includes(Recipient.piiFields, key),
          );
        case Participation.scopeName:
          return some(possibleKeys, (key) =>
            includes(Participation.piiFields, key),
          );
        case Activity.scopeName:
          return some(possibleKeys, (key) => includes(Activity.piiFields, key));
        default:
          return false;
      }
    }
    return false;
  }

  /**
   * @returns {string[]}
   */
  getPiiFields() {
    if (this.isPII() && this.nativeKey) {
      const piiField = this.nativeKey
        .split('.')
        .filter((chunk) => chunk !== '$' && !chunk.match(/^\d+$/))
        .join('.');
      return [piiField];
    }
    return [];
  }

  getResourceType() {
    switch (this.scopeName) {
      case Activity.scopeName:
        return 'activity';
      case Participation.scopeName:
        return 'participation';
      case Recipient.scopeName:
        return 'patient';
      default:
        return 'unknown';
    }
  }

  isNative() {
    return !!this.nativeKey;
  }

  isCustom() {
    return !this.isNative();
  }

  isEditable(isCreate = false) {
    if (this.evaluated) {
      return false;
    }
    if (this.disableUserEdits) {
      return false;
    }
    return isCreate || !this.immutable;
  }

  getTitle(language = 'en') {
    const schema = this.getJsonSchema({
      language,
    });
    return schema && schema.title;
  }

  isActivity() {
    return this.scopeName === Activity.scopeName;
  }

  isPatient() {
    return this.scopeName === Recipient.scopeName;
  }

  isParticipation() {
    return this.scopeName === Participation.scopeName;
  }

  isAtomic() {
    return isAtomic(this.getJsonSchema());
  }

  getIdentifierNamespace() {
    if (this.nativeKey !== 'identifiers.$.value') {
      return undefined;
    }
    const nativeKeyFilter = this.nativeKeyFilters && this.nativeKeyFilters[0];
    return nativeKeyFilter && nativeKeyFilter.namespace;
  }

  getKey(target) {
    const path = this.nativeKey || `variables.${this._id}`;
    if (!target) {
      return path;
    }
    return getKey(target, path, {
      arrayFilters: this.nativeKeyFilters,
    });
  }

  /**
   * @param {object} target
   * @param {unknown} value
   * @param {object} options
   * @param {boolean} [options.allowImmutable]
   * @param {string[]} [options.createRealm]
   * @param {string[]} [options.deleteRealm]
   * @returns {boolean}
   */
  setValue(target, value, options = {}) {
    if ((this.immutable && !options.allowImmutable) || this.evaluated) {
      return false;
    }
    const schema = this.getJsonSchema();
    const errors = checkSchema(schema, value);
    if (errors) {
      // NOTE: Compare with analogous case at getVariablesUpdatePipeline.
      if (value === null || (value === '' && schema && isAtomic(schema))) {
        try {
          deleteKey(target, this.getKey(), {
            requiredKeys: this.requiredKeys,
            arrayFilters: this.nativeKeyFilters,
            throwOnRequiredKey: true,
          });
          return true;
        } catch (err) {
          if (isRequiredKeyError(err)) {
            return false;
          }
          throw err;
        }
      }
      return false;
    }
    let valueToSet = value;
    if (this.valueSetter) {
      switch (this.valueSetter) {
        case 'domains': {
          const { createRealm = [], deleteRealm = [] } = options;
          valueToSet = getUpdatedOwnership(
            toOwnership(value),
            this.getRawValue(target),
            createRealm,
            deleteRealm,
          );
          break;
        }
        default: {
          return false;
        }
      }
    }
    setValue(target, this.getKey(), valueToSet, {
      modifiers: this.modifiers,
      arrayFilters: this.nativeKeyFilters,
    });
    return true;
  }

  getRawValue(target) {
    if (!target || !isObject(target)) {
      return undefined;
    }
    const { scopeName } = target.constructor;
    if (scopeName && this.scopeName && scopeName !== this.scopeName) {
      return undefined;
    }
    if (this.valueGetter) {
      switch (this.valueGetter) {
        case 'domains': {
          const value = get(target, this.nativeKey);
          if (value !== undefined) {
            return map(value, 'domain');
          }
          return undefined;
        }
        default:
          return undefined;
      }
    }
    if (this.nativeKey) {
      if (this.nativeKeyFilters) {
        return getValueWithArrayFilters(
          target,
          this.nativeKey,
          this.nativeKeyFilters,
        );
      }
      return get(target, this.nativeKey);
    }
    return target && target.variables && target.variables[this._id];
  }

  getSelector(value) {
    if (this.nativeKey) {
      if (this.nativeKeyFilters) {
        return getSelectorWithArrayFilters(
          value,
          this.nativeKey,
          this.nativeKeyFilters,
        );
      }
      return {
        [this.nativeKey]: value,
      };
    }
    return {
      [`variables.${this._id}`]: value,
    };
  }

  getValue(target) {
    const value = this.getRawValue(target);
    const schema = this.getJsonSchema();
    return cleanValue(schema, value);
  }

  getDisplayValue(value, options) {
    const schema = this.getJsonSchema(options);
    const { language, useDateTimeFormat } = options || {};
    const choices = schema.anyOf || schema.oneOf;
    if (choices) {
      const option = find(choices, {
        const: value,
      });
      if (!option || !option.title) {
        return '[unknown]';
      }
      return option.title;
    }
    if (isArray(value)) {
      if (schema && schema.type === 'array' && schema.items) {
        if (schema.items.type === 'string') {
          return map(value, csvEncode).join(',');
        }

        if (schema.items.type === 'object') {
          return value
            .map((obj) => {
              return Object.values(obj).join('/');
            })
            .join(', ');
        }
      }
      return '[array]';
    }
    if (isPlainObject(value)) {
      return '[object]';
    }
    if (
      language &&
      useDateTimeFormat &&
      schema &&
      schema.type === 'string' &&
      (schema.format === 'date' ||
        schema.format === 'partial-date' ||
        schema.format === 'year' ||
        schema.format === 'time' ||
        schema.format === 'instant')
    ) {
      return (
        value &&
        toIntlDateTimeFormat(
          language,
          getDisplayValueDate(schema.format, value),
          getDisplayValueDateFormatOptions(schema.format),
        )
      );
    }
    if (isDate(value)) {
      return value.toISOString();
    }
    if (isNil(value)) {
      return null;
    }
    switch (typeof value) {
      case 'string':
      case 'number':
      case 'boolean':
        return value;
      default:
        return '#ERR';
    }
  }

  getFromContext(context = {}) {
    // TODO: Replace magic strings with enums.
    switch (this.scopeName) {
      case '@project': {
        return this.getValue(context.project);
      }
      case '@milestone': {
        return this.getValue(context.milestone);
      }
      case '@recipient': {
        return this.getValue(context.recipient);
      }
      case '@participation': {
        return this.getValue(context.participation);
      }
      case '@activity': {
        return this.getValue(context.activity);
      }
      case '@answersSheet': {
        return this.getValue(context.answersSheet);
      }
      default:
        return undefined;
    }
  }

  getProjectAlias(projectId) {
    return this[`_project_${projectId}`] && this[`_project_${projectId}`].name;
  }

  getJsonSchema(options) {
    const { language = 'en', projectId, allowedDomains } = options || {};
    const params = {
      language,
      projectId,
      allowedDomains,
    };
    const key = `_getJsonSchema(${JSON.stringify(omitBy(params, isNil))})`;
    if (has(this, key)) {
      return this[key];
    }
    let jsonSchema;
    if (projectId) {
      const originalJsonSchema = this.getJsonSchema({
        ...options,
        projectId: null,
      });
      if (
        this[`_project_${projectId}`] &&
        this[`_project_${projectId}`].options &&
        !originalJsonSchema.anyOf &&
        !originalJsonSchema.oneOf &&
        !originalJsonSchema.enum
      ) {
        const anyOf = map(
          this[`_project_${projectId}`].options,
          ({ value, label }) => ({
            title: label,
            const: value,
          }),
        );
        if (originalJsonSchema.type === 'array') {
          jsonSchema = {
            ...originalJsonSchema,
            items: {
              ...originalJsonSchema.items,
              anyOf,
            },
          };
        } else {
          jsonSchema = {
            ...originalJsonSchema,
            anyOf,
          };
        }
      } else {
        jsonSchema = originalJsonSchema;
      }
      if (
        this.valueSetter === 'domains' &&
        allowedDomains &&
        jsonSchema.type === 'array' &&
        jsonSchema.items &&
        jsonSchema.items.type === 'string' &&
        !jsonSchema.anyOf &&
        !jsonSchema.oneOf &&
        !jsonSchema.enum
      ) {
        jsonSchema = {
          ...jsonSchema,
          items: {
            ...jsonSchema.items,
            anyOf: map(allowedDomains, (domain) => {
              if (domain && domain._id) {
                return {
                  const: domain._id,
                  title: domain.name || domain._id,
                };
              }
              return {
                const: domain,
                title: domain,
              };
            }),
          },
        };
      }
    } else if (language) {
      const originalJsonSchema = this.getJsonSchema({
        ...options,
        language: null,
      });
      let jsonSchemaI18n;
      try {
        jsonSchemaI18n = JSON.parse(
          this.jsonSchemaI18n && this.jsonSchemaI18n[language],
        );
      } catch (err) {
        jsonSchemaI18n = {}; // any
      }
      jsonSchema = merge(cloneDeep(originalJsonSchema), jsonSchemaI18n);
    } else {
      try {
        jsonSchema = JSON.parse(this.jsonSchema);
      } catch (err) {
        jsonSchema = {}; // any
      }
      if (isPlainObject(jsonSchema) && !jsonSchema.title) {
        jsonSchema.title = this.name;
      }
    }
    Object.defineProperty(this, key, {
      value: jsonSchema,
    });
    return jsonSchema;
  }

  getEmbeddedJsonSchema(schema = this.getJsonSchema()) {
    let embeddedSchema = schema;
    if (this.nativeKey) {
      /** @type {string[]} */
      const keyParts = this.nativeKey.split('.').reverse();
      /** @type {Record<string, unknown>[]} */
      const keyFilters = this.nativeKeyFilters
        ? [...this.nativeKeyFilters].reverse()
        : [];
      let j = 0;
      for (let i = 0; i < keyParts.length; i += 1) {
        if (keyParts[i] === '$') {
          if (keyFilters && keyFilters[j]) {
            embeddedSchema = {
              if: {
                properties: mapValues(keyFilters[j], (value) => {
                  return { enum: [value] };
                }),
              },
              then: embeddedSchema,
            };
          }
          embeddedSchema = {
            type: 'array',
            items: embeddedSchema,
          };
          j += 1;
        } else if (/^\d+$/.test(keyParts[i])) {
          const index = parseInt(keyParts[i], 10);
          embeddedSchema = {
            type: 'array',
            items: [...map(range(index), () => true), embeddedSchema],
          };
        } else {
          embeddedSchema = {
            type: 'object',
            properties: {
              [keyParts[i]]: embeddedSchema,
            },
          };
        }
      }
    } else {
      embeddedSchema = {
        type: 'object',
        properties: {
          variables: {
            type: 'object',
            properties: {
              [this._id]: embeddedSchema,
            },
          },
        },
      };
    }
    return embeddedSchema;
  }

  /**
   * Only return the properties which are relevant for setValue and getValue methods.
   *
   * In other words, two variables with the same definition will behave exactly the same
   * in terms of setValue and getValue.
   */
  getDefinition() {
    const {
      pii,
      valueSetter,
      valueGetter,
      nativeKey,
      nativeKeyFilters,
      requiredKeys,
      jsonSchema,
      scopeName,
    } = this;
    return {
      pii,
      valueSetter,
      valueGetter,
      nativeKey,
      nativeKeyFilters,
      requiredKeys,
      jsonSchema,
      scopeName,
    };
  }

  static fromBuiltIn({
    _id,
    name,
    question,
    pii,
    valueSetter,
    valueGetter,
    nativeKey,
    nativeKeyFilters,
    requiredKeys,
    modifiers,
    immutable,
    disableUserEdits,
    evaluated,
    fhir,
    widgetType,
    jsonSchema,
    jsonSchemaI18n,
    scopeName,
    patientServiceSafe,
    patientServiceId,
  }) {
    return new Variable({
      _id,
      ownership: [
        {
          domain: DOMAIN_PREFIX,
        },
      ],
      name: name || (question ? question.label : ''),
      pii,
      valueSetter,
      valueGetter,
      nativeKey,
      nativeKeyFilters,
      requiredKeys,
      modifiers,
      immutable: !!immutable,
      disableUserEdits: !!disableUserEdits,
      evaluated: !!evaluated,
      fhir,
      widgetType,
      jsonSchema: jsonSchema ? JSON.stringify(jsonSchema) : '{}',
      jsonSchemaI18n: mapValues(jsonSchemaI18n, (value) =>
        JSON.stringify(value),
      ),
      scopeName,
      patientServiceSafe,
      patientServiceId,
    });
  }

  /**
   * @param {object} doc
   * @param {object} [options]
   * @param {Record<string, Variable>} [options.variables]
   * @param {string[]} [options.piiFields]
   * @returns {object}
   */
  static omitPiiFields(doc, options = {}) {
    if (!doc) {
      return doc;
    }
    const { variables, piiFields } = options;
    /** @type {Record<string, unknown>} */
    let newDoc = { ...doc };
    /** @type {string[]} */
    const maskedFields = [];
    if (piiFields) {
      /** @type {string[]} */
      const keysToRemove = [];
      /** @type {Record<string, string[]>} */
      const otherKeys = {};
      forEach(piiFields, (field) => {
        const parts = field.split('.');
        if (parts.length > 1) {
          const key = parts[0];
          const subKey = parts.slice(1).join('.');
          otherKeys[key] = otherKeys[key] || [];
          otherKeys[key].push(subKey);
        } else if (has(newDoc, field)) {
          keysToRemove.push(field);
          maskedFields.push(field);
        }
      });
      if (!isEmpty(keysToRemove)) {
        newDoc = omit(newDoc, keysToRemove);
      }
      if (!isEmpty(otherKeys)) {
        newDoc = mapValues(newDoc, (value, key) => {
          const piiFieldsForThisKey = otherKeys[key];
          if (!isEmpty(piiFieldsForThisKey)) {
            if (isArray(value)) {
              return map(value, (item, index) => {
                if (isObject(item)) {
                  const { maskedDoc, maskedFields: nestedMaskedFields } =
                    Variable.omitPiiFields(item, {
                      ...options,
                      piiFields: piiFieldsForThisKey,
                    });
                  nestedMaskedFields.forEach((m) =>
                    maskedFields.push(`${key}.${index}.${m}`),
                  );
                  return maskedDoc;
                }
                return item;
              });
            }
            if (isObject(value)) {
              const { maskedDoc, maskedFields: nestedMaskedFields } =
                Variable.omitPiiFields(value, {
                  ...options,
                  piiFields: piiFieldsForThisKey,
                });
              nestedMaskedFields.forEach((m) =>
                maskedFields.push(`${key}.${m}`),
              );
              return maskedDoc;
            }
          }
          return value;
        });
      }
    }
    if (variables && 'variables' in newDoc && isObject(newDoc.variables)) {
      const variablesToRemove = filter(Object.keys(newDoc.variables), (key) => {
        const variable = variables && variables[key];
        return !variable || variable.isPII();
      });
      if (!isEmpty(variablesToRemove)) {
        Array.prototype.push.apply(
          maskedFields,
          map(variablesToRemove, (key) => `variables.${key}`),
        );
        newDoc.variables = omit(newDoc.variables, variablesToRemove);
      }
    }

    return {
      maskedDoc: newDoc,
      maskedFields,
    };
  }
}

Variable.collection = 'Variables';

export default Variable;
