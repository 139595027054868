"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toTimeString = exports.isTimeString = void 0;
const isValidTime_1 = require("./isValidTime");
const timeRegEx = /^\d{2}:\d{2}:\d{2}/;
const isTimeString = (isoString) => {
    return (timeRegEx.test(isoString) &&
        isoString.length === 8 &&
        (0, isValidTime_1.isValidTime)(isoString));
};
exports.isTimeString = isTimeString;
const toTimeString = (isoString) => {
    if (!timeRegEx.test(isoString) || !(0, isValidTime_1.isValidTime)(isoString)) {
        throw new Error('Expected a time string in the format HH:MM:SS');
    }
    return isoString.substring(0, 8);
};
exports.toTimeString = toTimeString;
