/* eslint-disable import/prefer-default-export */
import React from 'react';
import map from 'lodash/map';
import isArray from 'lodash/isArray';
import compact from 'lodash/compact';
import { Bed, List, CalendarAlt } from 'styled-icons/fa-solid';
import {
  VARIABLE_ID__PARTICIPATION_EMR_SYNC,
  VARIABLE_ID__BIRTH_DATE,
  VARIABLE_ID__PARTICIPATION_CATEGORY,
  VARIABLE_ID__PARTICIPATION_BED_NUMBER,
  VARIABLE_ID__MRN,
} from '../../../../common/constants';

export const labels = {
  [VARIABLE_ID__PARTICIPATION_EMR_SYNC]: 'EMR Sync:',
  [VARIABLE_ID__MRN]: 'MRN:',
  [VARIABLE_ID__BIRTH_DATE]: <CalendarAlt />,
  [VARIABLE_ID__PARTICIPATION_CATEGORY]: <List />,
  [VARIABLE_ID__PARTICIPATION_BED_NUMBER]: <Bed />,
};

export const getVariable = (variables, variableId) => {
  if (isArray(variableId)) {
    return compact(map(variableId, (id) => getVariable(variables, id))).join(
      ' ',
    );
  }
  return variables && variables[variableId];
};
