import { daysInMonth, formatAsDateString, isValidDate } from '@zedoc/time';
import { computeAge } from '@zedoc/date';

/**
 * @typedef {{ __type: 'DOBString '} & string} DOBString
 */

/** @type {Date | undefined} */
let dateNow;

/**
 * @typedef {object} TimeOptions
 * @property {import('@zedoc/time').TimeZone} [timezone]
 */

function getDateNow() {
  return dateNow || new Date();
}

/**
 * @param {TimeOptions} [options]
 * @returns {string}
 */
function getDateStringNow(options = {}) {
  return formatAsDateString(getDateNow(), {
    timezone: options.timezone,
  });
}

class DOB {
  /**
   * @param {object} options
   * @param {DOBString} [options.dobString]
   */
  constructor(options) {
    /**
     * @private
     * @type {string | undefined}
     */
    this.dobString = options.dobString;
  }

  toString() {
    return this.dobString || '';
  }

  getYear() {
    if (!this.dobString) {
      return undefined;
    }
    return new Date(this.dobString).getFullYear();
  }

  isYearOnly() {
    return !!this.dobString && this.dobString.length === 4;
  }

  isValid() {
    return !!this.dobString;
  }

  /**
   * @param {TimeOptions} [options]
   */
  getCurrentAge(options = {}) {
    if (!this.dobString) {
      return NaN;
    }
    return computeAge(this.dobString, getDateStringNow(options));
  }

  /**
   * @param {TimeOptions} [options]
   */
  getNextBirthday(options = {}) {
    if (!this.dobString) {
      return undefined;
    }
    const age = this.getCurrentAge(options);
    if (!Number.isFinite(age)) {
      return undefined;
    }

    // NOTE: We transform to date to handle situations,
    //       when dobString is not a full date.
    const dob = new Date(this.dobString);
    const year = dob.getFullYear() + age + 1;

    let month = dob.getMonth() + 1;
    let day = dob.getDate();

    const nDays = daysInMonth(month, year);
    if (nDays < day) {
      // NOTE: Technically, this can only happen in February,
      //       so we don't need to check of month overflows.
      month += 1;
      day -= nDays;
    }

    return `${year.toString().padStart(4, '0')}-${month
      .toString()
      .padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
  }

  /**
   * @param {string} dateString
   */
  static fromString(dateString) {
    if (!isValidDate(dateString)) {
      return new this({});
    }
    return new this({
      dobString: /** @type {DOBString} */ (dateString),
    });
  }

  /**
   * @param {string | number} value
   */
  static fromStringOrNumber(value) {
    if (typeof value === 'number') {
      return this.fromString(`${value}`);
    }
    return this.fromString(value);
  }

  /**
   * @param {Date} date
   */
  static mockDateNow(date) {
    dateNow = date;
  }

  static cleanMocks() {
    dateNow = undefined;
  }

  static getDateNow() {
    return getDateNow();
  }
}

export default DOB;
