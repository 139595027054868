// @ts-check
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import {
  FILTER_TYPE__ANY_OF,
  FILTER_TYPE__PROPERTY,
  FILTER_TYPE__VARIABLE,
  FILTER_CONDITION__CONTAINS_ELEMENT,
} from '../common/constants';
import { isIdentifier } from '../common/search/types';

/**
 * @typedef {import('../common/search/types').Filter} Filter
 */

/** @type {<T extends Filter>(filters: T[]) => Filter[]} */
let cleanListOfFilters;

/**
 * @template {Filter} T
 * @param {T} filter
 * @returns {Filter}
 */
export const cleanFilter = (filter) => {
  const { id, name, type, condition, state, settings } = filter;
  return {
    id,
    name,
    type,
    condition,
    state,
    settings: {
      ...settings,
      ...(settings &&
        settings.filters && {
          filters: cleanListOfFilters(settings.filters),
        }),
    },
  };
};

/**
 * @template {Filter} T
 * @param {T[]} filters
 * @returns {Filter[]}
 */
cleanListOfFilters = (filters) => {
  /** @type {Filter[]} */
  const clean = [];
  /** @type {string | undefined} */
  let groupKey;
  /** @type {Filter[]} */
  let groupFilters = [];

  function maybeAddArrayElementFilter() {
    if (groupFilters.length === 1) {
      clean.push(groupFilters[0]);
    } else if (groupKey && groupFilters.length > 1) {
      const [type, id] = groupKey.split(',');
      if (
        isIdentifier(id) &&
        (type === FILTER_TYPE__PROPERTY || type === FILTER_TYPE__VARIABLE)
      ) {
        clean.push({
          type,
          condition: FILTER_CONDITION__CONTAINS_ELEMENT,
          settings: {
            id,
            filters: groupFilters,
          },
        });
      }
    }
    groupKey = undefined;
    groupFilters = [];
  }

  /**
   * @param {string} key
   * @param {Filter} filter
   */
  // function addGroupFilter(key, filter) {
  //   groupKey = key;
  //   groupFilters.push(cleanFilter(filter));
  // }

  for (let i = 0; i < filters.length; i += 1) {
    const filter = filters[i];
    if (
      'meta' in filter &&
      isObject(filter.meta) &&
      'arrayKey' in filter.meta &&
      typeof filter.meta.arrayKey === 'string' &&
      isIdentifier(filter.meta.arrayKey)
    ) {
      const newGroupKey = `${filter.type},${filter.meta.arrayKey}`;
      if (groupKey && groupKey !== newGroupKey) {
        maybeAddArrayElementFilter();
      }
      // addGroupFilter(newGroupKey, filter);
      clean.push(cleanFilter(filter));
    } else {
      maybeAddArrayElementFilter();
      clean.push(cleanFilter(filter));
    }
  }
  maybeAddArrayElementFilter();
  return clean;
};

/**
 * @template {Filter} T
 * @param {T[]} filters
 * @param {object} [options]
 * @param {boolean} [options.anyOf]
 * @returns {Filter[]}
 */
const cleanFilters = (filters, options = {}) => {
  const { anyOf } = options;
  if (anyOf) {
    if (isEmpty(filters)) {
      return [];
    }
    return [
      {
        type: FILTER_TYPE__ANY_OF,
        settings: {
          filters: cleanListOfFilters(filters),
        },
      },
    ];
  }
  return cleanListOfFilters(filters);
};

export default cleanFilters;
