import React, { useMemo } from 'react';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Markdown } from '@zedoc/react-markdown';
import isEmpty from 'lodash/isEmpty';
import { apiCurrentUserWelcomeContent } from '../../common/api/currentUser';
import useDocumentTitle from '../../utils/useDocumentTitle';
import PageBar from '../../components/Layout/PageBar';
import CurrentUser from '../../models/CurrentUser';
import useList from '../../utils/useList';

const Welcome = compose(
  connect(() =>
    createStructuredSelector({
      currentUser: CurrentUser.select.user(),
      currentUserRoles: CurrentUser.select.getAllRolesIds(),
    }),
  ),
)(({ currentUser, currentUserRoles }) => {
  const { t, i18n } = useTranslation();

  useDocumentTitle([t('entry:welcome')]);

  const { ready, items } = useList(
    apiCurrentUserWelcomeContent.withParams({
      resultId: '$meta.id',
    }),
  );

  const hasWelcomeContent = ready && !isEmpty(items);

  const content = useMemo(() => {
    const item = items.find(({ language }) => language === i18n.language);

    return item ? item.markdown : items[0]?.markdown;
  }, [items, i18n.language]);

  return (
    <div className="stack-0 flex-1">
      <PageBar title="&nbsp;" />
      <div className="flex-1 h-full flex justify-center items-center stack-2">
        <p className="text-xl">
          {t('entry:welcome')}
          {', '}
          <b>{currentUser && currentUser.getFullName()}</b>!
        </p>
        {hasWelcomeContent && (
          <p className="max-w-prose text-center">
            <Markdown source={content} />
          </p>
        )}
        {isEmpty(currentUserRoles) && (
          <p className="text-sm">{t('entry:accountIsBeingPrepared')}</p>
        )}
      </div>
    </div>
  );
});

export default Welcome;
