import {
  FILTER_TYPE__PROPERTY,
  FILTER_CONDITION__INCLUDE,
  FILTER_CONDITION__EXCLUDE,
  FILTER_CONDITION__DATE_EQUALS,
  FILTER_CONDITION__DATE_NOT_EQUAL,
  FILTER_CONDITION__DATE_SAME_OR_AFTER,
  FILTER_CONDITION__DATE_SAME_OR_BEFORE,
  FILTER_CONDITION__DATE_AFTER,
  FILTER_CONDITION__DATE_BEFORE,
} from '../../../../common/constants';
import {
  PARTICIPATION_STATE__ACTIVE,
  PARTICIPATION_STATE__ENDED,
} from '../../../../common/constants/collections/Participations';
import { FILTER_CATEGORY__OTHER } from '../../../../components/Filters';

const defaultFilters = [
  {
    name: 'Active patients',
    type: FILTER_TYPE__PROPERTY,
    condition: FILTER_CONDITION__INCLUDE,
    settings: {
      id: 'state',
    },
    state: {
      include: [PARTICIPATION_STATE__ACTIVE],
    },
    meta: {
      category: FILTER_CATEGORY__OTHER,
      lockCondition: true,
      lockState: true,
      conditions: [FILTER_CONDITION__INCLUDE, FILTER_CONDITION__EXCLUDE],
    },
  },
  {
    name: 'Discharged patients',
    type: FILTER_TYPE__PROPERTY,
    condition: FILTER_CONDITION__INCLUDE,
    settings: {
      id: 'state',
    },
    state: {
      include: [PARTICIPATION_STATE__ENDED],
    },
    meta: {
      category: FILTER_CATEGORY__OTHER,
      lockCondition: true,
      lockState: true,
      conditions: [FILTER_CONDITION__INCLUDE, FILTER_CONDITION__EXCLUDE],
    },
  },
  {
    name: 'Enrollment date',
    type: FILTER_TYPE__PROPERTY,
    condition: FILTER_CONDITION__DATE_EQUALS,
    settings: {
      id: 'createdAt',
    },
    meta: {
      category: FILTER_CATEGORY__OTHER,
      conditions: [
        FILTER_CONDITION__DATE_EQUALS,
        FILTER_CONDITION__DATE_NOT_EQUAL,
        FILTER_CONDITION__DATE_SAME_OR_AFTER,
        FILTER_CONDITION__DATE_SAME_OR_BEFORE,
        FILTER_CONDITION__DATE_AFTER,
        FILTER_CONDITION__DATE_BEFORE,
      ],
    },
  },
];

export default defaultFilters;
