/* eslint-disable react/prop-types */
import React from 'react';
import { components } from 'react-select';

const ValueContainer = (props) => {
  const {
    innerProps,
    selectProps: { testId },
  } = props;

  return (
    <components.ValueContainer
      innerProps={{
        ...innerProps,
        'data-testid': `${testId}-value-container`,
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

export default ValueContainer;
