"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatAsTimeString = exports.formatAsDateString = exports.getDateParts = void 0;
const TimeZone_1 = require("./TimeZone");
const dateTimeFormatCache = {};
function makeDTF(timezone) {
    if (!dateTimeFormatCache[timezone]) {
        dateTimeFormatCache[timezone] = new Intl.DateTimeFormat('en-US', {
            // NOTE: There's a BUG in Chrome, which makes hour12: false not to work as expected.
            //       For example, format(new Date('2020-09-27T11:00:00.000Z')) in "Pacific/Auckland"
            //       gives "09/28/2020, 24:00:00", but it should be "09/28/2020, 00:00:00".
            //       A possible workaround is to request 23 hour cycle explicitly as per:
            //       https://support.google.com/chrome/thread/29828561?hl=en
            // hour12: false,
            hourCycle: 'h23',
            timeZone: timezone,
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        });
    }
    return dateTimeFormatCache[timezone];
}
const typeToIndex = {
    year: 0,
    month: 1,
    day: 2,
    hour: 3,
    minute: 4,
    second: 5,
};
const toInteger = (str) => parseInt(str, 10);
function hackyOffset(dateTimeFormat, date) {
    const formatted = dateTimeFormat.format(date).replace(/\u200E/g, '');
    const parsed = /(\d+)\/(\d+)\/(\d+),? (\d+):(\d+):(\d+)/.exec(formatted);
    if (!parsed) {
        throw new Error('Error while parsing date.');
    }
    const [, month, day, year, hour, minute, second] = parsed;
    return [year, month, day, hour, minute, second].map(toInteger);
}
function partsOffset(dateTimeFormat, date) {
    const formatted = dateTimeFormat.formatToParts(date);
    const filled = [];
    for (let i = 0; i < formatted.length; i += 1) {
        const { type, value } = formatted[i];
        const index = typeToIndex[type];
        if (index !== undefined) {
            filled[index] = toInteger(value);
        }
    }
    return filled;
}
const UTC = (0, TimeZone_1.toTimeZone)('UTC');
const getDateParts = (ts, options = {}) => {
    const { timezone = UTC } = options;
    const dateTimeFormat = makeDTF(timezone);
    const date = ts !== undefined ? new Date(ts) : new Date();
    if (Number.isNaN(date.getTime())) {
        throw new Error('Invalid date');
    }
    const [year, month, day, hour, minute, second] = dateTimeFormat.formatToParts &&
        typeof dateTimeFormat.formatToParts === 'function'
        ? partsOffset(dateTimeFormat, date)
        : hackyOffset(dateTimeFormat, date);
    return {
        year,
        month,
        day,
        hour,
        minute,
        second,
    };
};
exports.getDateParts = getDateParts;
function formatAsDateString(ts, options = {}) {
    const { year, month, day } = (0, exports.getDateParts)(ts, options);
    return `${year.toString().padStart(4, '0')}-${month
        .toString()
        .padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
}
exports.formatAsDateString = formatAsDateString;
function formatAsTimeString(ts, options = {}) {
    const { hour, minute, second } = (0, exports.getDateParts)(ts, options);
    return `${hour.toString().padStart(2, '0')}:${minute
        .toString()
        .padStart(2, '0')}:${second.toString().padStart(2, '0')}`;
}
exports.formatAsTimeString = formatAsTimeString;
