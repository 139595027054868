"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isValidTime = void 0;
const isValidTime = (isoString) => {
    const parts = isoString.split(':');
    if (parts.length === 0 || parts.length > 3) {
        return false;
    }
    if (parts.length >= 1) {
        if (parts[0].length !== 2) {
            return false;
        }
        const hours = +parts[0];
        if (Number.isNaN(hours) || hours < 0 || hours > 24) {
            return false;
        }
    }
    if (parts.length >= 2) {
        if (parts[1].length !== 2) {
            return false;
        }
        const minutes = +parts[1];
        if (Number.isNaN(minutes) || minutes < 0 || minutes > 59) {
            return false;
        }
    }
    if (parts.length >= 3) {
        if (parts[2].length !== 2) {
            return false;
        }
        const seconds = +parts[2];
        if (Number.isNaN(seconds) || seconds < 0 || seconds > 59) {
            return false;
        }
    }
    return true;
};
exports.isValidTime = isValidTime;
