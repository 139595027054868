import isObject from 'lodash/isObject';
import isPlainObject from 'lodash/isPlainObject';
import every from 'lodash/every';
import isNil from 'lodash/isNil';
import isDate from 'lodash/isDate';
import isArray from 'lodash/isArray';
import isRegExp from 'lodash/isRegExp';
import map from 'lodash/map';

export const NOTHING = /** @type {const} */ ({ _id: -1 });

export const EVERYTHING = /** @type {const} */ ({});

/**
 * @typedef {(
 *  | null
 *  | string
 *  | number
 *  | boolean
 *  | RegExp
 *  | Date
 * )} AtomicEJSONValue
 */

/**
 * @param {unknown} value
 * @returns {value is AtomicEJSONValue}
 */
export function isAtomicEJSONValue(value) {
  if (
    isNil(value) ||
    isRegExp(value) ||
    isDate(value) ||
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value === 'boolean'
  ) {
    return true;
  }
  return false;
}

/**
 * @typedef {import('./types.selector').EJSONValue} EJSONValue
 */

/**
 * @typedef {import('./types.selector').EJSONObject} EJSONObject
 */

/**
 * @typedef {import('./types.selector').EJSONArray} EJSONArray
 */

/**
 * @param {unknown} value
 * @returns {value is EJSONValue}
 */
export function isEJSONValue(value) {
  if (isAtomicEJSONValue(value)) {
    return true;
  }
  if (isArray(value)) {
    return every(value, isEJSONValue);
  }
  if (isObject(value) && isPlainObject(value)) {
    return every(value, isEJSONValue);
  }
  return false;
}

/**
 * @param {string} value
 * @returns {value is import('./types.selector').Identifier}
 */
export function isIdentifier(value) {
  return /^[_0-9a-zA-Z]/.test(value);
}

/**
 * @param {unknown} value
 * @returns {asserts value is EJSONValue}
 */
export function validateEJSONValue(value) {
  if (!isEJSONValue(value)) {
    throw new Error(`Invalid EJSON value: ${value}`);
  }
}

/**
 * @typedef {object} MatchedProperty
 * @property {string} key
 * @property {unknown} value
 * @property {Record<string, unknown>} [metadata]
 */

/**
 * @typedef {boolean | MatchedProperty[]} MatchResult
 */

/**
 * @typedef {(value: EJSONValue) => MatchResult} MatchFn
 */

/**
 * @typedef {(value: EJSONValue, key: string) => MatchResult} MatchWithKeyFn
 */

/**
 * @typedef {object} CompiledSelector
 * @property {MatchFn} match
 * @property {MatchWithKeyFn} matchWithKey
 * @property {(transform: (field: MatchedProperty) => MatchedProperty) => CompiledSelector} map
 * @property {(newMatch: MatchFn) => CompiledSelector} combine
 */

/**
 * @param {MatchFn} match
 */
export function createMatchWithKey(match) {
  /**
   * @param {EJSONValue} value
   * @param {string} key
   * @returns {MatchResult}
   */
  function matchWithKey(value, key) {
    const matchResult = match(value);
    if (matchResult === false) {
      return false;
    }
    if (matchResult === true) {
      return [{ key, value }];
    }
    return map(matchResult, (property) => {
      return {
        ...property,
        key: `${key}.${property.key}`,
      };
    });
  }
  return matchWithKey;
}

/**
 * @typedef {object} FilterState
 * @property {Array<string | boolean | null>} [exclude]
 * @property {Array<string | boolean | null>} [include]
 * @property {string} [timezone]
 * @property {string} [text]
 * @property {boolean} [exists]
 * @property {string} [tagName]
 * @property {string} [tagType]
 * @property {Date | string | number} [threshold]
 */

/**
 * @typedef {'number' | 'string' | 'boolean'} FilterAtomicValueType
 */

/**
 * @typedef {FilterAtomicValueType | 'array'} FilterValueType
 */

/**
 * @typedef {import('../constants/filters').FilterType} FilterType
 */

/**
 * @typedef {import('../constants/filters').FilterCondition} FilterCondition
 */

/**
 * @typedef {object} FilterSettings
 * @property {import('./types.selector').Identifier} [id] - either custom variable id or native property key
 * @property {Array<Filter>} [filters]
 * @property {import('./types.selector').Identifier} [namespace]
 * @property {import('./types.selector').Identifier} [valueKey]
 * @property {FilterValueType} [valueType]
 * @property {FilterAtomicValueType} [arrayItemsType]
 * @property {import('./types.selector').Identifier} [labelKey]
 * @property {import('./types.selector').Identifier} [tagNameKey]
 * @property {import('./types.selector').Identifier} [tagTypeKey]
 */

/**
 * @typedef {object} Filter
 * @property {string} [id]
 * @property {string} [name]
 * @property {FilterType} type
 * @property {FilterCondition} [condition]
 * @property {FilterState} [state]
 * @property {FilterSettings} settings
 */
